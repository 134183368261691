
html {
    /* background-color: #ebeef0; */
}

.info_full_width {
    width: 100%;
}

.main_content {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
}

body, h1 {
    font-family: americansans;
}

body {
    max-width: 1800px;
    margin-left: auto;
    margin-right: auto;
}

.epays_nav_title {
    font-size:26px;
    font-family:americansans_medium;
}

.epays_main_title {
    color:#0078d2;
    font-size: 26px;
}

a {
    color: #0078d2;
    text-decoration: none;
}

a:hover, a:focus {
    text-decoration: none;
    color: #4db4fa;
}

.epays_nav {
    border-radius:0px;
    font-family: americansans_light;
    margin-bottom:0px;
}

.epays_menu_button_container {
    padding:8px;
}

.epays_menu_button {
    /*background: #ffffff;
    color:transparent;*/
    background: transparent;
    color:#ffffff !important;
    border: 3px solid white;
    border-radius: 8px;
}

.epays_menu_button.logout_desktop {
    /*background: #ffffff;
    color:transparent;*/
    background: transparent;
    color:#ffffff !important;
    border: 0px;
    font-size:16px;
    font-family:americansans;
}



.epays_menu_button.collapsed {
    background: transparent;
    color:#ffffff;
}

.epays-tab-container {

}

#epays-tab-overlay, .epays-arrow {
    display:none;
}

.epays_tabs {
    height:60px;
    height:auto;
    margin-bottom:16px;
}

.epays_desktop_tabs {
    height: 44px;
    background-color: #EBEEF0;
}

.epays_desktop_tabs li {
    border-radius:0px;
    font-size: 16px;
}

.nav-item.active > a.nav-link {
    color: #0078d2 !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-color: #ffffff !important;
}

.nav-item > a.nav-link {
    color: #9da6ab !important;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom: 2px solid #9da6ab !important;
}

@media screen and (max-width: 860px) {
    .epays_desktop_tabs li {
        border-radius:0px;
        font-size: 12px;
    }
}


@media screen and (max-width: 768px) {
    .epays-tab-container {
        position: relative;
        display: block;
        border: 2px solid #ebeef0;
        padding-bottom: 16px;
    }
    .epays_desktop_tabs {
        /*display:none;*/
        height: 40px;
        overflow: hidden;
        transition: height 0.4s linear;
        background-color: #ffffff;
    }
    .epays-tab-container.open > .epays_desktop_tabs {
        height:150px;
    }
    #epays-tab-overlay {
        position:absolute;
        display:block;
        top:0px;
        left:0px;
        height:100%;
        width:100%;
        z-index:4;
    }

    .epays-arrow {
        display:block;
        position: absolute;
        height: 26px;
        width: 26px;
        background-color: #0078d2;
        z-index: 3;
        right: 12px;
        top: 14px;
        transition: all 0.2s linear;
        -webkit-mask-image: url(images/circle_arrow_01.svg);
        content: "";
    }

    .epays-tab-container.open .epays-arrow {
        transform: rotate(180deg);
    }

    .epays-tab-container.open > #epays-tab-overlay {
        display:none;
    }
    .epays_desktop_tabs li {
        background-color: #ffffff;
        position:absolute;
        width: 100%;
        padding:12px;
    }
    .epays_desktop_tabs li.active {
        z-index: 2;
    }

    .epays-tab-container.open .epays_desktop_tabs li {
        position:relative;
        border-bottom: 1px solid #ebeef0;
        background-color: transparent;
    }
    .epays_desktop_tabs li.nav-item a.nav-link {
        text-align:left;
        font-size: 20px;
        margin: 0px 10px;
        padding: 0px;
        border-bottom: 0px !important;
    }
}

.epays_mobile_tabs {
    width:100%;
}

@media screen and (min-width: 768px) {
    .epays_main {
        margin-right: 318px;
    }
}

/* Selector box styles */

.epays_selector {
    display:block;
    margin-bottom: 28px;
    height: 60px;
}

.epays_selector p {
    margin-bottom: 0px;
    color: #36495a;
}

@media screen and (min-width: 830px) {
    .epays_selector {
        height: 26px;
        margin-bottom:10px;
    }
    .epays_selector p {
        display:inline-block;
    }
}

.epays_selector select {
    border-radius: 6px;
    padding: 0px 12px;
    border: 1px solid #9da6ab;
    min-width: 75px;
}

.epays_selector span {
    margin:0px 6px;
    color: #36495a;
}

.epays_selector .btn {
    margin-bottom: 0px;
    padding: 0px 28px;
    margin-top: -2px;
    height: 24px;
    margin-left: 14px;
    background-color: #0078d2;
    color: #ffffff;
    border-radius: 0px;
}

@media screen and (max-width: 768px) {
    .epays_selector .btn {
        height: 24px;
        margin: 12px 0px;
        width: 100%;
        background-color: #0078d2;
        color: #ffffff;
        border-radius: 0px;
    }
}


/* Drop-down menu styles --------------------------------- */

.epays_menu {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height: 100vh !important;
}

.epays_menu h3.heading {
    color: #00467F;
}

.epays_menu h3.heading, .epays_menu p.hist_link {
    border-bottom: 1px solid #ebeef0;
    padding-bottom:6px;
}

.epays_menu p.hist_link {
    padding-left: 8px;
    font-size:16px;
}

.epays_menu .help_box {
    padding: 16px;
    box-sizing: border-box;
    background:#ebeef0;
    margin-bottom: 12px;
    margin-top: 30px;
}

.epays_menu .help_box h3 {
    margin-top: 4px;
    color:#00467F;
}

.epays_menu .help_box .help_link {

}

.help_link:hover {
    text-decoration: none;
}

.help_link:before {
    font-family: icons;
    height: 20px;
    width: 20px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin-top: -2px;
    margin-right: 6px;
}

.help_link.chat:before {
    -webkit-mask-image: url(images/chat_01.svg);
    content: "";
}

.help_link.case:before {
    -webkit-mask-image: url(images/pen_01.svg);
    content: "";
}

.help_link.cases:before {
    -webkit-mask-image: url(images/file_01.svg);
    content: "";
}

.help_link.phone:before {
    -webkit-mask-image: url(images/phone_01.svg);
    content: "";
}

.help_link:before {
    background: #0078d2;
}

.help_link:hover::before {
    background: #4db4fa;
}

.epays_menu .logout {
    border-radius: 0px;
}

@media screen and (min-width: 768px) {
    .epays_menu {
        display:block !important;
        width: 332px;
        float: right;
    }
}


/* Pay table styles ------------------------------------- */

.aavp_charge_container {
    overflow-y: overlay;
    height: calc(100% - 34px);
    min-width: 1024px;
}
.pay_item_container {
    color: grey;
    text-decoration: none;
}

.pay_item .money {
    color:#36495a;
    /*color:#00B989;*/
}

@media screen and (max-width: 768px) {
    .pay_item {
        display: block;
        margin-bottom: 18px;
    }
    
    .pay_item .date_line {
        display: block;
        width: 100%;
        background-color: #ebeef0; 
        height: 26px;
        line-height: 28px;
        margin-bottom: 6px;
        background-image: url(images/arrow_right.svg);
        background-repeat: no-repeat;
        background-size: 17px;
        background-position: center right;
        background-position: 98%;
    }

    .pay_item div {
        padding: 0px 12px;
    }
    
    .pay_item > .left {
        display:inline-block;
    }
    
    .pay_item .money {
        color:green;
    }
    
    .pay_item > .right {
        display: inline-block;
        float: right;
    }
    .pay_item > .note_block {
        margin-bottom:12px;
    }
}


/* UPDATED FOR VIEW AND PAY */

/* @media screen and (min-width: 768px) { -------------------   REMOVING MOBILE SWITCH */
    .aavp_table_header {
        background-color: #EBEFF0;
        min-height: 34px;
        padding: 0px 12px;
        font-weight: bold;
        border-bottom: 1px solid #D0DAE0;
        min-width: 1024px;
    }
    .aavp_table_header > .pay_item {
        line-height: 37px;
    }
    .aavp_table_header > .charge_item {
        line-height: 37px;
    }
    .charge_item_container {
        padding: 12px 12px;
        display: block;
        border-bottom: 1px solid#ebeef0;
        /* overflow-y: scroll; */
    }
    .pay_item > div {
        display:inline-block;
        vertical-align: top;
        color:#36495a;
        /*color:#00B989;*/
    }

    .charge_item > div {
        display:inline-block;
        vertical-align: middle;
        color: #36495a;
        line-height: normal;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .seven_columns > div {
        width:14.2%;
    }

    .six_columns > div {
        width:16.5%;
    }


    .pay_item > .date_line {
        width:20%;
    }
    .pay_item > div > a {
        display: inline-block;
        width: 100%;
    }
    /* .pay_item > div > a:after {
        content: "";
        height: 12px;
        width: 12px;
        background-color: #0078d2;
        position: relative;
        display: inline-block;
        margin-left: 8px;
        -webkit-mask-image: url(images/arrow_right.svg);
        mask-image: url(images/arrow_right.svg);
    } */

/* } -----------------------------------------------------------   ENDING BRACE FOR MOBILE ---------------------*/

/* Color overrides */

.bg-primary {background-color: #0078d2;}
.bg-secondary {background-color: #00467f;}
.bg-success {background-color: #00b989;}
.text-primary{color:#36495a;}


/* Color reference codes... */
/*
$blue: #0078d2;
$red: #F52305;
$aa-blue: #0078D2;
$aa-lightblue: #4DB4FA;
$aa-ultralightblue: #E1F0FA;
$aa-darkblue: #00467F;
$aa-highlight-blue: #e1f0fa;
$aa-detroit-blue: #0061ab;
$aa-blue-gray: #627a88;
$aa-teal: #B1E1EB;
$aa-green: #00B989;
$aa-yellow: #D1D532;
$aa-lightorange: #FAAF00;
$aa-orange: #FF7318;
$aa-red: #F52305;
$aa-darkred: #C30019;
$aa-white: #ffffff;
$aa-black: #131313;
$aa-darkgray: #36495A;
$aa-mediumgray: #6E8999;
$aa-gray: #9DA6AB;
$aa-lightgray: #D0DAE0;
$aa-ultralightgray: #EBEFF0;
$aa-menu-bg-gray: #F5F5F5;
$aa-halfway-lightgray: #e7ecef;
*/
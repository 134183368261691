@import '~bootstrap/dist/css/bootstrap.min.css';
@import './styles/epays_main.css';
@import './assets/fonts/fonts.css';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.emp-portal-body{
    background-color: #D0DAE0;
    max-width: 1800px;
    min-width: 800px;
    min-height: 500px;    
    overflow: overlay;
    margin: auto;
}

html, body {
    height: 100%;
    
  }

.upload-notif {
    color: #ffffff !important;
    background-color:  #00467F !important;
    /* border-color: #66a6d6 !important; */
    margin-top: 70px !important;
}
.upload-notif .mat-simple-snackbar-action {
    color: #66a6d6  !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #2A79FF!important;
    width: 20px;
    height: 20px;
}

/* #main_container {
    overflow: hidden;
    position: relative;
    flex: 1 1 auto;
  } */

.app-overlay-container {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
  }
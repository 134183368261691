@font-face {
    font-family: 'americansans';
    src: url('americansans-regular.eot'); /* IE9 Compat Modes */
    src: url('americansans-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('americansans-regular.woff2') format('woff2'), /* Modern Browsers */
        url('americansans-regular.woff') format('woff'), /* Modern Browsers */
        url('americansans-regular.ttf') format('truetype'); /* Safari, Android, iOS */
        /* url('americansans-regular.svg') format('svg'); Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'americansans_medium';
    src: url('americansans-medium.eot'); /* IE9 Compat Modes */
    src: url('americansans-medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        url('americansans-medium.woff2') format('woff2'), /* Modern Browsers */
        url('americansans-medium.woff') format('woff'), /* Modern Browsers */
        url('americansans-medium.ttf') format('truetype') /* Safari, Android, iOS */
        /* url('americansans-medium.svg') format('svg'); Legacy iOS */;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'americansans_light';
    src: url('americansans-light.eot'); /* IE9 Compat Modes */
    src: url('americansans-light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
        /* url('americansans-light.woff2') format('woff2'), Modern Browsers */
        url('americansans-light.woff') format('woff'), /* Modern Browsers */
        url('americansans-light.ttf') format('truetype') /* Safari, Android, iOS */
        /* url('americansans-light.svg') format('svg'); Legacy iOS */;
    font-weight: normal;
    font-style: normal;
}

/*@font-face {
    font-family: 'icons';
    src: url('americansans-icons-v4.eot');
    src: url('americansans-icons-v4.eot?#iefix') format('embedded-opentype'),
        url('americansans-icons-v4.woff2') format('woff2'),
        url('americansans-icons-v4.woff') format('woff'),
        url('americansans-icons-v4.ttf') format('truetype'),
        url('americansans-icons-v4.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}*/

/*
@font-face {
    font-family: americansans_medium;
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/americansans-medium-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/americansans-medium-webfont.woff2") format("woff2"), url("/fonts/americansans-medium-webfont.woff") format("woff"), url("/fonts/americansans-medium-webfont.ttf") format("truetype"), url("/fonts/americansans-medium-webfont.svg#americansans_medium") format("svg");
}

@font-face {
    font-family: "americansans";
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/americansans-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/americansans-regular-webfont.woff2") format("woff2"), url("/fonts/americansans-regular-webfont.woff") format("woff"), url("/fonts/americansans-regular-webfont.ttf") format("truetype"), url("/fonts/americansans-regular-webfont.svg#americansans") format("svg");
}

@font-face {
    font-family: "americansans";
    font-style: italic;
    font-weight: normal;
    src: url("/fonts/americansans-regularitalic-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/americansans-regularitalic-webfont.woff2") format("woff2"), url("/fonts/americansans-regularitalic-webfont.woff") format("woff"), url("/fonts/americansans-regularitalic-webfont.ttf") format("truetype"), url("/fonts/americansans-regularitalic-webfont.svg#americansans") format("svg");
}

@font-face {
    font-family: "americansans";
    font-style: normal;
    font-weight: bold;
    src: url("/fonts/americansans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/americansans-bold-webfont.woff2") format("woff2"), url("/fonts/americansans-bold-webfont.woff") format("woff"), url("/fonts/americansans-bold-webfont.ttf") format("truetype"), url("/fonts/americansans-bold-webfont.svg#americansans") format("svg");
}

@font-face {
    font-family: "americansans_bold";
    font-style: normal;
    font-weight: bold;
    src: url("/fonts/americansans-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/americansans-bold-webfont.woff2") format("woff2"), url("/fonts/americansans-bold-webfont.woff") format("woff"), url("/fonts/americansans-bold-webfont.ttf") format("truetype"), url("/fonts/americansans-bold-webfont.svg#americansans_bold") format("svg");
}

@font-face {
    font-family: "americansans_light";
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/americansans-light-webfont.eot?#iefix") format("embedded-opentype"), url("/fonts/americansans-light-webfont.woff2") format("woff2"), url("/fonts/americansans-light-webfont.woff") format("woff"), url("/fonts/americansans-light-webfont.ttf") format("truetype"), url("/fonts/americansans-light-webfont.svg#americansans_light") format("svg");
}

@font-face {
    font-family: "american-icons";
    font-style: normal;
    font-weight: normal;
    src: url("/fonts/american-icons.eot?#iefix") format("embedded-opentype"), url("/fonts/american-icons.woff2") format("woff2"), url("/fonts/american-icons.woff") format("woff"), url("/fonts/american-icons.ttf") format("truetype"), url("/fonts/american-icons.svg#american-icons") format("svg");
}

@font-face {
    font-family: 'jiveglyphs';
    src: url("/fonts/jiveglyphs.eot?gn1ogf");
    src: url("/fonts/jiveglyphs.eot?gn1ogf#iefix") format("embedded-opentype"), url("/fonts/jiveglyphs.ttf?gn1ogf") format("truetype"), url("/fonts/jiveglyphs.woff?gn1ogf") format("woff"), url("/fonts/jiveglyphs.svg?gn1ogf#jiveglyphs") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 900;
    src: local('Raleway Black Italic'), local('Raleway-BlackItalic'), url("/fonts/1Ptpg8zYS_SKggPNyCgwzqZ_DNCb_Vo.woff2") format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 900;
    src: local('Raleway Black Italic'), local('Raleway-BlackItalic'), url("/fonts/1Ptpg8zYS_SKggPNyCgwzqZ_AtCb.woff2") format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
*/